import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import classNames from 'classnames';
import { PrismicNextImage } from '@prismicio/next';
import { useEffect, useState } from 'react';
import styles from './StatsMedia.module.scss';
/**
 * Props for `StatsMedia`.
 */
export type StatsMediaProps = SliceComponentProps<Content.StatsMediaSlice>;

/**
 * Component for "StatsMedia" Slices.
 */
const StatsMedia = ({ slice }: StatsMediaProps): JSX.Element => {
  const { items, primary } = slice;
  const { background_color, heading, description, image } = primary;
  const [bg, setBg] = useState();
  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    }
  }, [])
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={classNames(styles.root, 'wide-container', styles[bg || background_color], styles[slice.variation], 'color-changing-section')}
      data-background={background_color}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <h2>{heading}</h2>
          <h4>{description}</h4>
        </div>
        <div className={styles.body}>
          <PrismicNextImage field={image} />
          <div className={styles.statsWrapper}>
            {items.map(item => (
              <div className={styles.statsCard} key={item.stats_text}>
                <span className={styles.statsText}>{item.stats_text}</span>
                <span className={styles.statsDescription}>{item.stats_description}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatsMedia;
